import React, { useEffect, useState } from "react";
import './userSideBar.css'
import { Link, useNavigate, useLocation } from "react-router-dom";
import femaleProfilePic from '../../Assets/femaleprofile.png';
import maleProfilePic from '../../Assets/maleprofile.png';
import { AiOutlineAudit, AiOutlineUser } from "react-icons/ai";
import { TbArrowLeftFromArc } from "react-icons/tb";
import { BsTicketDetailed } from "react-icons/bs";
import { BiFile } from "react-icons/bi";

const CorporateUserSideBar = () => {
    const user = JSON.parse(localStorage.getItem('CorporateEmp'));
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };
    return (
        <>
            <div className='col-lg-2 col-md-2 col-sm-3 col-3 border-end border-2' id='usersidebar' style={{ minHeight: "790px" }}>
                <div className='sideBar'>
                    <div className='userProfile'>
                        {
                            user? (user.gender==="female" ? <img className='eventImg' src={femaleProfilePic} alt='profilePic'></img>
                                : <img className='eventImg' src={maleProfilePic} alt='profilePic'/>
                            ):''
                        }
                        {/* <img className='eventImg' src={SampleImg} alt='profilePic'></img> */}
                        <h4 className='username mt-3'>Hi, {user ? user.First_name : "Guest"}</h4>
                    </div>
                    <div className='side-NavBar'>
                        <div className={`nav-contents ${activeLink === "/corporate/userprofile" ? "bg-primary text-white" : ""}`}>
                            <Link to="/corporate/userprofile" className='link-dark link-underline-opacity-0 fw-semibold'
                                onClick={() => handleLinkClick("/corporate/userprofile")}>
                                <AiOutlineUser /> <span>Profile</span>
                            </Link>
                        </div>
                        <div className={`nav-contents ${activeLink === "corporateevent/yourtickets" ? "bg-primary text-white" : ""}`}>
                            <Link to="/yourtickets" className='link-dark link-underline-opacity-0 fw-semibold'
                                onClick={() => handleLinkClick("corporateevent/yourtickets")}>
                                <BsTicketDetailed /> <span>Your Tickets</span>
                            </Link>
                        </div>
                        {/* <div className={`nav-contents ${activeLink === "/list-your-event" ? "bg-primary text-white" : ""}`}>
                            <Link to="/list-your-event" className='link-dark link-underline-opacity-0 fw-semibold'
                                onClick={() => handleLinkClick("/list-your-event")}>
                                <BiFile /> <span>List your Events</span> 
                            </Link>
                        </div>
                        <div className={`nav-contents ${activeLink === "/hosted-events" ? "bg-primary text-white" : ""}`}>
                            <Link to="/hosted-events" className='link-dark link-underline-opacity-0 fw-semibold '
                                onClick={() => handleLinkClick("/hosted-events")}>
                                <BiFile /> <span>Event Hosted</span>
                            </Link>
                        </div> */}
                        <div className='nav-contents'>
                            <Link to="/" className='link-dark link-underline-opacity-0 fw-semibold'
                                onClick={handleLogout}>
                                <TbArrowLeftFromArc /> <span>Logout</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorporateUserSideBar;
