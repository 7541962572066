import { IoSearch } from "react-icons/io5"
import './MobileSearchView.css'
import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import CategoryImages from '../../Assets/CategoryImages.js'

const MobileSearchView = ({ onData }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const [searchEntered, setSearchEntered] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [searchHistory, setSearchHistory] = useState([]);


    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        if (value) {
            handleSearch(value);
        } else {
            setResults([]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchEntered(true)
            handleSearch(query);
        }
    };

    const handleSearch = async (searchQuery) => {
        if (!searchQuery) return; // Avoid searching if query is empty
        try {
            const response = await axios.get(`${API_URL}/api/search`, {
                params: {
                    query: searchQuery,
                },
            });
            const res = response.data;
            setResults(res);
            if (typeof onData === 'function') {
                onData(res);
            }
            console.log("RESULT : ", results)
        } catch (error) {
            setError('Error fetching data');
        }
    };

    const highlightText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <span key={index} className="highlight">
                            {part}
                        </span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);

        // Get the day of the week
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const dayOfWeek = dayNames[dateObj.getDay()];

        // Get the day, month, and year
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);

        return `${dayOfWeek}, ${day} ${month}`;
    };

    const handleClose = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const handleEventCategory = (cat) => {
        navigate(`/category/${cat}`);
    };

    useEffect(() => {
        loadSearchHistory();
        (async () => {
            // const listofCategory = await axios.get(`${API_URL}/api/category`);
            setCategories(CategoryImages);
        })();
    }, []);


    const loadSearchHistory = () => {
        const history = JSON.parse(localStorage.getItem('searchHistory')) || [];
        setSearchHistory(history);
    };

    const saveSearchHistory = (newHistory) => {
        localStorage.setItem('searchHistory', JSON.stringify(newHistory));
        setSearchHistory(newHistory);
    };

    // Clear search history
    const handleClearHistory = () => {
        localStorage.removeItem('searchHistory');
        setSearchHistory([]);
    };

    // const StoreSearchHistory = (SearchItem) => {
    //     console.log("Search ITEM : ", SearchItem)
    //     let updatedHistory = [...searchHistory];
    //     if (!updatedHistory.includes(SearchItem) && SearchItem.length > 3) {
    //         updatedHistory.unshift(SearchItem); // Add new search to the start
    //         if (updatedHistory.length > 5) {
    //             updatedHistory = updatedHistory.slice(0, 5); // Limit history to 5 searches
    //         }
    //         saveSearchHistory(updatedHistory);
    //     }
    // }

    const StoreSearchHistory = (SearchItem, eventLink) => {
        console.log("Search ITEM : ", SearchItem);
    
        // Create an object with the search item and its corresponding link
        const searchHistoryItem = { event: SearchItem, link: eventLink };
    
        // Check if the item already exists in the history, using both event and link as unique identifiers
        let updatedHistory = [...searchHistory];
        const isItemExist = updatedHistory.some(item => item.event === SearchItem && item.link === eventLink);
    
        // Only add the new search history if it's not already present and if the search item is more than 3 characters
        if (!isItemExist && SearchItem.length > 3) {
            updatedHistory.unshift(searchHistoryItem);  // Add the new search to the beginning
    
            // Limit the history to 5 entries
            if (updatedHistory.length > 5) {
                updatedHistory = updatedHistory.slice(0, 5);
            }
    
            // Save the updated search history
            saveSearchHistory(updatedHistory);
        }
    };
    

    return (
        <>
            <div className="mt-3">
                <div className="mobilesearchDiv px-2">
                    <div style={{ width: "90%" }}>
                        <div className="d-flex h-100">
                            <div className="searchIcon-mobile">
                                <IoSearch className="searchIcon mt-1" style={{ color: "#999999" }} size={25} />
                            </div>
                            <div className="searchTab-mobile">
                                <input className='searchTab-input dropdown-item'
                                    placeholder='Search by Event, Artist, Venue...'
                                    value={query}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="searchTab-close" style={{ color: "#999999" }}>
                        <RxCross2 size={25} onClick={handleClose} />
                    </div>
                </div>
                <hr />



                {
                    searchEntered ?
                        <>
                            <div className=" mt-3 px-4">
                                <div className="search_history mb-2">
                                    <span> {results.length} Product Found</span>
                                </div>
                                {
                                    results && results.length > 0 ? (
                                        results.map(searchItem => (
                                            <div className="mb-2">
                                                <Link style={{ textDecoration: "none" }} onClick={() => StoreSearchHistory(searchItem.event_name, `/${searchItem.event_city}/${searchItem.event_name.replace(/\s+/g, '_')}/${searchItem.unique_id}`)}
                                                    to={`/${searchItem.event_city}/${searchItem.event_name.replace(/\s+/g, '_')}/${searchItem.unique_id}`}
                                                    key={searchItem._id}
                                                >
                                                    <div className="card" key={searchItem._id}>
                                                        <div className="card-body d-flex">
                                                            <div className="searchdata-image">
                                                                <img src={`${searchItem?.cover_image}`} width={100} alt="event" />
                                                            </div>
                                                            <div>
                                                                <div className="search_history">{searchItem.event_name}</div>
                                                                <div className="history_clear" style={{ color: "#6A6A6A" }}>{formatDate(searchItem.event_starts_date)}
                                                                    {searchItem.event_venue ? `| ${searchItem.event_venue}` : ''}  {searchItem.city ? `| ${searchItem.city}` : ''}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Search Found!</p>
                                    )
                                }
                            </div>
                        </>
                        :
                        <>
                            {
                                query ?
                                    <>
                                        {results.length > 0 && (
                                            <div className="px-5 my-2">
                                                {results.map((result) => (
                                                    <div>
                                                        <Link className="search_links" 
                                                            to={`/${result.event_city}/${result.event_name.replace(/\s+/g, '_')}/${result.unique_id}`}
                                                            key={result._id}
                                                            onClick={() => {setQuery(result.event_name || result.Artist); StoreSearchHistory(result.event_name, `/${result.event_city}/${result.event_name.replace(/\s+/g, '_')}/${result.unique_id}`)}}
                                                        >
                                                            {highlightText(result.event_name || result.Artist, query)}
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <div className=" mt-3 px-4">
                                            <div className=" d-flex  justify-content-between">
                                                <div><span className="search_history">Your search history  </span></div>
                                                <div><span className="history_clear" onClick={handleClearHistory} style={{ cursor: "pointer" }}>Clear</span></div>
                                            </div>
                                            <div className="history mt-3">
                                                {searchHistory.length > 0 ? (
                                                    searchHistory.map((item, index) => (
                                                        <div className="history_List" key={index}>
                                                           <Link className="search_links"  to={item.link}>
                                                           {item.event.length > 7 ? item.event.slice(0,7) + "..." : item.event}
                                                           </Link>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No Search History</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className=" mt-3 px-4">
                                            <div>
                                                <span className="categoryBrowser">Browse by category</span>
                                            </div>
                                            <div>
                                                <div className="history">
                                                    {categories.map((category, index) => (
                                                        <div className="card searchcategorycard" key={index}>
                                                            {/* <span className="text-center categoryblock m-auto"
                    onClick={() => handleEventCategory(category.category)}>
                    {category.category}
                  </span> */}
                                                            <div className="card-img"
                                                                onClick={() => handleEventCategory(category.category)}
                                                            >
                                                                <img className="searchcategory-img" src={category.image} alt={category.category} />
                                                            </div>
                                                            <span className="text-center categoryblock m-auto"
                                                                onClick={() => handleEventCategory(category.category)}
                                                            >
                                                                {category.category}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }

            </div>
        </>
    )
}


export default MobileSearchView