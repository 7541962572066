import React, { useEffect, useState } from 'react';
import NavBar from '../Components/NavBar';
import LocationModal from '../Components/LocationModal';
import Carousel from '../Components/Carousel';
import BrowseByCategory from '../Components/BrowseByCategory';
import Footer from '../Components/Footer';
import Trending from '../Components/Trending';
import PuffLoader from "react-spinners/PuffLoader";
import { useSelector } from 'react-redux';

function HomePage() {
    const city = useSelector((state) => state.city);
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [loading, setLoading] = useState(true); // New state for loader specific to the components

    // Simulate loading time or actual async data fetching
    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setLoading(false); // Stop loading after 2 seconds (or once data is ready)
        }, 2000); // Adjust timing as needed

        return () => clearTimeout(loadTimeout); // Cleanup on unmount
    }, []);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    useEffect(() => {
        // Scroll to top when the component loads or data is done loading
        if (!loading) {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Adds smooth scrolling
            });
        }
    }, [loading]);

    return (
        <div>
            {/* Show LocationModal */}
            {loading ? (
                <>
                </>
                // <div className='d-flex justify-content-center align-items-center' style={{ height: "300px" }}>
                //     {/* <PuffLoader loading={loading} /> */}
                // </div>
            ) : (
                <>
                    {showLocationModal && (
                        <LocationModal
                            onClose={handleCloseLocationModal}
                            onLocationChange={handleLocationChange}
                        />
                    )
                    }
                </>
            )
            }
            {/* Render Navbar Component */}
            <NavBar
                userLocation={city}
                onNext={() => setShowLocationModal(true)}
            />

            {/* Show loader only around Carousel, BrowseByCategory, and Trending */}
            {loading ? (
                <>
                    <div className='loaderShowing d-flex justify-content-center align-items-center' style={{height:"100vh"}}>
                        <PuffLoader loading={loading} />
                    </div>
                </>
            ) : (
                <>
                    {/* Render Carousel */}
                    <Carousel />

                    {/* Browse By Category */}
                    <BrowseByCategory />

                    {/* Trending event list */}
                    <Trending userLocation={city} />
                </>
            )}

            {/* Render Footer */}
            <Footer />
        </div>
    );
}

export default HomePage;
