import { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
// import { Button } from "react-bootstrap";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import auth from "../../firebaseConfig";
import apiService from "../../services/apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import "./CorporateLoginModal.css";
import SyncLoader from "react-spinners/SyncLoader";
import indian_flag from '../../Assets/flag.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../config";
import axios from "axios";

/* global grecaptcha */

function CorporateLoginModal({ onLoginSuccess, onNext }) {
    const [phone, setPhone] = useState("");
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState("");
    const [showOTPField, setShowOTPField] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const recaptchaRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [usernotLogin, setusernotLogin] = useState(false)


    useEffect(() => {
        let countdown;
        if (isResendDisabled && showOTPField) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [isResendDisabled, showOTPField]);

    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emp_id, setEmp_id] = useState("");
    const [phone_number, setPhone_number] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [renderFlag, setRenderFlag] = useState("");
    const location = useLocation();
    const userData = apiService.getData();
    const [proceedBooking, setProceedBooking] = useState(false);

    const handleDateChange = (date) => {
        setDob(date);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const body = {
            Email: email,
            Emp_id: emp_id,
        }
        try {
            const SaveLogin = await axios.post(`${API_URL}/corporate/emp-login`, body)
            if (SaveLogin.status === 200) {
                localStorage.setItem("CorporateEmp", JSON.stringify(SaveLogin.data.employee));
                onNext(true, SaveLogin.data.employee);
                onLoginSuccess()
            }
        } catch (error) {
            alert("Unable to Login! Please try after sometime.")
        }
    }

    return (
        <div className="fullscreen-login">
            <div className="coplogin-containermodal">
                <span>
                    <BsXCircle onClick={onLoginSuccess} className="copclosebtnX bg-white rounded-pill" />
                </span>
                <span className="bookhead" style={{ marginTop: "-5rem" }}>
                    Book <span className="your-fs"> Your Front Seat</span>
                </span>
                <p className="cop-login">Login</p>
                <form>
                    <div className="div-num mb-3">
                        <p className="enter-number">Email Id:</p>
                        <input
                            className="form-control mobileinput py-3 border-primary"
                            type="text"
                            name="email"
                            placeholder="Enter your email id"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="div-num mb-5">
                        <p className="enter-number">Employee_Id:</p>
                        <input
                            className="form-control mobileinput py-3 border-primary"
                            type="text"
                            name="emp_id"
                            placeholder="Enter your employee id"
                            value={emp_id}
                            onChange={(e) => setEmp_id(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={handleLogin} className="btn LoginbookBtn btn-primary w-50" disabled={loading}>
                        {loading ? <SyncLoader animation="border" color="#FFFF" size="10" speedMultiplier={1} margin={4} /> : "Login"}
                    </button>

                    <div className='text-primary mt-2' style={{ fontSize: "10px" }}>
                        I accept the Terms of Use & Privacy Policy
                    </div>
                </form>

            </div>
            <div id="recaptcha" ref={recaptchaRef}></div>
        </div>
    );
}

export default CorporateLoginModal;
