import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config.js';
import './Carousel.css';

function Carousel() {
  const [CaraousalsImages, setCaraousalsImages] = useState([]);

  useEffect(() => {
    const getCarouselImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/carousel_img`);
        if (response.status === 200 && response.data?.carousel_img) {
          setCaraousalsImages(response.data.carousel_img);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getCarouselImages();
  }, []);

  // Manually initialize the carousel
  useEffect(() => {
    if (CaraousalsImages.length > 0) {
      const carousel = document.querySelector("#carouselExampleSlidesOnly");
      if (carousel) {
        new window.bootstrap.Carousel(carousel, {
          interval: 5000, // Ensure the interval is set
          ride: "carousel", // Start automatically
          touch: true,
          pause: "false"
        });
      }
    }
  }, [CaraousalsImages]);

  return (
    <div id="carouselExampleSlidesOnly" className="carouselDiv carousel slide" data-bs-ride="carousel" data-bs-interval="5000" data-bs-touch="true" data-bs-pause="false">
      <div className="carousel-indicators">
        {CaraousalsImages.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carouselExampleSlidesOnly"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current={index === 0 ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner innercarouselDiv">
        {CaraousalsImages.map((image, index) => (
          <div key={index} className={`carousel-item carouselimgDiv px-1 ${index === 0 ? 'active' : ''}`} data-bs-interval="5000">
            <img src={image.image} alt={`Banner ${index + 1}`} className="d-block cover-image" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
