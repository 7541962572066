import React, { useState } from 'react'
import corpLogo from '../../Assets/corpLogo.png'
import './NavBar.css'
import { IoSearch } from "react-icons/io5"
import { Link, useNavigate } from 'react-router-dom'
import { FiX } from "react-icons/fi";
import { TbUser } from "react-icons/tb";
import { BsList } from "react-icons/bs";
import { TbArrowLeftFromArc } from "react-icons/tb";
import { API_URL } from '../../config'
import axios from "axios";
import { AiOutlineUser } from "react-icons/ai";
import { BsTicketDetailed } from "react-icons/bs";
import femaleProfilePic from '../../Assets/femaleprofile.png';
import maleProfilePic from '../../Assets/maleprofile.png';
import { useSelector } from 'react-redux'

function CorporateNavBar({ onNext, onData, isLoggedIn }) {
    const city = useSelector((state) => state.city);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const userLoggedIn = JSON.parse(localStorage.getItem('CorporateEmp'));
    const highlightText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <span key={index} className="highlight">
                            {part}
                        </span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        if (value) {
            handleSearch(value);
        } else {
            setResults([]);
        }
    };
    const handleSearch = async (searchQuery) => {
        try {
            const response = await axios.get(`${API_URL}/api/search`, {
                params: {
                    query: searchQuery,
                },
            });
            const res = response.data;
            setResults(res);
            if (typeof onData === 'function') {
                onData(res);
            }
            console.log("RESULT : ", results)
        } catch (error) {
            setError('Error fetching data');
        }
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(query);
        }
    };
    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };
    return (
        <>
            <div className='row' id='largeScreenDiv'>
                <div className="navigationBar">
                    <div className='logoDiv'>
                            <img className='logoImg' src={corpLogo} />
                    </div>
                    <div className='navDiv'>
                        <div className='searchDiv'>
                            <div className='setcentersearch'>
                                <div className='search'>
                                    <IoSearch className="searchIcon" size={25} />
                                    <input className='search-input'
                                        placeholder='Search by Event, Artist, Venue...'
                                        value={query}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                {results.length > 0 && (
                                    <div className="dropdown searchSuggestions">
                                        <ul className="list-group dropdown-menu" style={{ width: "72%" }}>
                                            {results.map((result) => (
                                                <Link
                                                    to={`/${result.event_city}/${result.event_name.replace(/\s+/g, '_')}/${result.unique_id}`}
                                                    key={result._id}
                                                    className="dropdown-item"
                                                    onClick={() => setQuery(result.event_name || result.Artist)}
                                                >
                                                    {highlightText(result.event_name || result.Artist, query)}
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='userDiv'>
                        <div className='user'>
                            {userLoggedIn ?
                                <Link to='/corporateevent/yourtickets' className='userlink'>
                                    <TbUser className='fw-semibold fs-5 text-primary' />
                                    {
                                        isLoggedIn ?
                                            <span className='ps-1'> Hi, {userLoggedIn ? userLoggedIn?.Employee_Name : "Guest"} </span> :
                                            <span className='ps-1'> Hi, {userLoggedIn ? userLoggedIn?.Employee_Name : "Guest"} </span>
                                    }

                                </Link> :
                                // <Link to='/login?source=login' className='userlink' >
                                //     <TbUser className='fw-bold fs-5 text-primary' />
                                //     <span className='ps-1'> Login </span>
                                // </Link>
                                ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Div for Mobile Device */}
            <div className='row' id='smallScreenDiv'>
                <div className='navbar'>
                    <div className="dropdown togglebtn">
                        <BsList className='dropdown-toggle fs-1 ms-2 fw-bold'
                            data-bs-toggle="dropdown"
                            aria-expanded="false" aria-controls="responsiveNavbar"
                        />
                        <ul className="dropdown-menu collapseNavbar">
                            {/* <div className='d-flex justify-content-end pe-2'>
                                <FiX className='text-dark' size={30} />
                            </div> */}
                            <li >
                                <div className='userProfile'>
                                    {
                                        userLoggedIn ? (userLoggedIn.gender === "female" ? <img className='userProfilePic' src={femaleProfilePic} alt='profilePic'></img>
                                            : <img className='userProfilePic' src={maleProfilePic} alt='profilePic' />
                                        ) : ''
                                    }
                                    {/* <img src={sample} className='userProfilePic' /> */}
                                    <h5 className='fw-bold text-dark'> Hi, {userLoggedIn ? userLoggedIn?.Employee_Name : "Guest"}</h5>
                                </div>
                            </li>
                            <li>
                                <div className='sidenavcontent'>
                                    <ul>
                                        {
                                            userLoggedIn ? <>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/corporate/userprofile" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <AiOutlineUser /> <span>Profile</span>
                                                    </Link>
                                                </li>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/corporateevent/yourtickets" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <BsTicketDetailed /> <span>Your Ticket</span>
                                                    </Link>
                                                </li>

                                            </> : ''
                                        }
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            {userLoggedIn ?
                                                <Link to='/' className='link' onClick={handleLogout}>
                                                    <TbArrowLeftFromArc className='text-dark' />
                                                    <span className='ps-1 text-dark fw-semibold'> Logout </span>
                                                </Link> :
                                                // <Link to='/corporate/login' className='link' >
                                                //     <TbArrowLeftToArc className='text-dark' />
                                                //     <span className='ps-1 text-dark fw-semibold'> Login </span>
                                                // </Link>
                                                ""
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='logo-Image'>
                            <img src={corpLogo} className='logomobileView' />
                    </div>
                    <div className='searchbtn dropdown'>
                        <IoSearch className="searchIcon dropdown-toggle" size={30} 
                        onClick={()=>navigate('/search')} 
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorporateNavBar