import React, { useEffect, useState } from 'react'
import './TicketBooking.css'
import successTick from '../../Assets/successTick.png'
import My_Front_Seat_Logo from '../../Assets/My Front Seat Logo.png'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { SlArrowDown } from 'react-icons/sl';
import { BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import ReduceText from '../Interface';
import CorporateLoginModal from './CorporateLoginModal';
import SyncLoader from "react-spinners/SyncLoader";
import { PiSealCheckFill } from "react-icons/pi";

function CorporateTicketBookingSummary({ ticket, eventDetail, ticketids, onLogin }) {
    const user = JSON.parse(localStorage.getItem('CorporateEmp'));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let [billing, SetBilling] = useState({
        ticketQuantity: 0,
        subTotal: 0,
        bookingFee: 0,
        gst: 0,
        basic_price: 0,
        total_amount: 0
    });

    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()]; // Get month name
        const year = String(dateObj.getFullYear()).slice(-2); // Get last two digits of the year
        return `${day} ${month} '${year}`;
    };

    const handleBilling = () => {
        let count = 0;
        let amount = 0;
        let basic_rate = 1;

        count = ticket.bookingQuantities;
        basic_rate = (basic_rate * ticket.basic_price) * ticket.bookingQuantities;
        amount = ticket.bookingQuantities * ticket.ticket_price;

        console.log("::::AMOUNT::::", amount)
        // for (let i = 0; i < ticket.length; i++) {
        //   count = count + ticket[i].bookingQuantities;
        //   basic_rate = basic_rate + ticket[i].basic_price;
        //   amount = amount + (ticket[i].bookingQuantities * ticket[i].ticket_price);
        // }
        const gst9 = (Math.ceil(basic_rate * 0.09 * 100) / 100).toFixed(2);
        const fee = Number(basic_rate) + (2 * Number(gst9));
        const totalAmount = amount + fee;



        SetBilling({
            ticketQuantity: count,
            subTotal: amount,
            bookingFee: fee,
            basic_price: basic_rate,
            gst: Number(gst9),
            // total_amount: Number(totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })),
            total_amount: Number(totalAmount),
        });
    };

    useEffect(() => {
        console.log("TICKET: ", ticket, billing)
        if (ticket) {
            handleBilling();
        }
    }, [ticket, eventDetail]);


    //  Payment Process
    const [orderId, setOrderId] = useState("");

    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleClose = () => setShowLoginModal(false);
    const handleShow = () => setShowLoginModal(true);

  

    // function to store non-payable tickets (Free Tickets)
    const handleSaveData = async () => {
        console.log("handle save ticket called", user)
        const body = {
            amount: billing.total_amount,
            user_id: user._id || user.uid,
            ticketDetails: ticketids,
            eventDetails: eventDetail,
            quantity: billing.ticketQuantity,
            eventreserveDate: ticket.reserveDate
        }

        const saveTicketResponse = await axios.post(`${API_URL}/corporate/bookfreecorporatetickets`, body)
        if (saveTicketResponse.status === 200) {
            axios.post(`${API_URL}/corporate/sendcorporatemail`, {
                orderId: saveTicketResponse.data.freeTicketBooking.order_id,
                email: user.Email,
                event_name: eventDetail.event_name,
                userName: user.Employee_Name,
                ticket_link: `https://www.myfrontseat.in/yourtickets/${saveTicketResponse.data.freeTicketBooking._id}`,
                vendor_id: eventDetail._id,
                ticketName: ticket.ticketName,
                bookingfee: billing.bookingFee,
                basicRate: ticket.basic_price,
                gst: billing.gst,
                totalBasicPrice: billing.basic_price,
                cover_image: eventDetail.cover_image,
                phone_no: user.Phone_number,
                event_date: formatDate(eventDetail.event_start_date),
                event_city: eventDetail.event_city,
                event_time: formatTime(eventDetail.event_start_Time),
                event_venue: eventDetail.event_venue ? eventDetail.event_venue + "," + eventDetail.event_city : eventDetail.event_city
            })
        } else {
            alert("Failed to book tickets");
        }
    }
    const handleGetTicket = () => {
        setLoading(true);
        if (localStorage.getItem("CorporateEmp")) {
            console.log("handle get ticket called procceed next")
            handleSaveData();
            navigate(`/corporateevent/ticketbooked/${(eventDetail.event_name).replace(/\s+/g, '_')}`);
        } else {
            handleShow();
        }
        setLoading(false);
    }

    const handleLoginSuccess = () => {
        onLogin(user);
        setShowLoginModal(false);
        // setIsLoggedIn(true); // Update the login state
    };

    useEffect(() => {
        // Scroll to top when the component loads or data is done loading
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Adds smooth scrolling
        });
    }, []);

    const handleNextProcess = async (valueReceive, userData) =>{
        if(valueReceive){
            console.log("handle save ticket called", userData)
        const body = {
            amount: billing.total_amount,
            user_id: userData._id,
            ticketDetails: ticketids,
            eventDetails: eventDetail,
            quantity: billing.ticketQuantity,
            eventreserveDate: ticket.reserveDate
        }

        const saveTicketResponse = await axios.post(`${API_URL}/corporate/bookfreecorporatetickets`, body)
        if (saveTicketResponse.status === 200) {
            axios.post(`${API_URL}/corporate/sendcorporatemail`, {
                orderId: saveTicketResponse.data.freeTicketBooking.order_id,
                email: userData.Employee_Name,
                event_name: eventDetail.event_name,
                userName: (userData.First_name+ userData.Last_name),
                ticket_link: `https://www.myfrontseat.in/yourtickets/${saveTicketResponse.data.freeTicketBooking._id}`,
                vendor_id: eventDetail._id,
                ticketName: ticket.ticketName,
                bookingfee: billing.bookingFee,
                basicRate: ticket.basic_price,
                gst: billing.gst,
                totalBasicPrice: billing.basic_price,
                cover_image: eventDetail.cover_image,
                phone_no: userData.Phone_number,
                event_date: formatDate(eventDetail.event_start_date),
                event_city: eventDetail.event_city,
                event_time: formatTime(eventDetail.event_start_Time),
                event_venue: eventDetail.event_venue ? eventDetail.event_venue + "," + eventDetail.event_city : eventDetail.event_city
            })

            navigate(`/corporateevent/ticketbooked/${(eventDetail.event_name).replace(/\s+/g, '_')}`);
        } else {
            alert("Failed to book tickets");
        }
        }
    }

    return (
        <>
            {/* Show LocationModal */}
            {showLoginModal && (
                <CorporateLoginModal
                    showModal={showLoginModal}
                    onLoginSuccess={handleLoginSuccess}
                    onNext={handleNextProcess} 
                />
            )}

            <div className='bookingticketmainDiv' id="largeScreenViewPort">
                <div className='bookingticket'>
                    <div className='row'>
                        <div className='col-md-1 col-sm-1 col-1'>
                            <span className='backarrowlink link-dark me-md-5 me-1 mt-2' data-bs-toggle="modal" data-bs-target="#exampleModal"><BsArrowLeft size={30} /></span>
                        </div>
                        <div className='col-md-11 col-sm-11 col-11'>
                            <div className='headingdiv'>
                                <span className='heading'>{eventDetail.event_name}</span>
                                <span className='subheading'>
                                    {eventDetail.event_venue} {eventDetail.event_city} | {formatDate(ticket.reserveDate) ? formatDate(ticket.reserveDate) : formatDate(eventDetail.event_starts_date)} | {formatTime(eventDetail.event_starts_Time)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <p className='bookinghead'>Booking Summary</p>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='eventimg'>
                                <img
                                    className="booking-img rounded-4 w-100 h-100"
                                    src={`${eventDetail.cover_image}`}
                                    alt="booking"
                                />
                                <span className='ticketname'>
                                    {/* {
                    ticket && ticket.length > 0 ? (
                      ticket.map((tic, index) => (
                        <span key={tic._id}>
                          {
                            tic.bookingQuantities > 0 &&
                            (
                              <>
                                {index > 0 && ","} {tic.ticket_Name}
                              </>
                            )
                          }
                        </span>
                      ))
                    ) : (
                      ""
                    )
                  } */}
                                    <span>
                                        {ticket.ticket_Name}
                                    </span>
                                </span><br />
                                <span className='quantity'>{billing.ticketQuantity} Tickets</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "200px" }}>

                </div>

                <div className="footerPart proceedpay-div">
                    <button className="proceedpay-btn" onClick={handleGetTicket} disabled={loading}>
                        {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Get your Ticket"}</button>
                </div>
            </div>

            <div id="smallScreenViewPort">
                <div className="row headerpart border-bottom">
                    <div className="col-1 d-flex justify-content-center align-items-center">
                        <span className='backarrow link-dark' data-bs-toggle="modal" data-bs-target="#exampleModal"><BsArrowLeft size={24} /></span>
                    </div>
                    <div className='col-11 d-flex justify-content-center align-items-center'>
                        <span className='topheading fw-semibold'>Confirm Your Details</span>
                    </div>
                </div>
                <div className='ticketbody'>
                    <p className='summaryheading fw-semibold'>Booking Summary</p>
                </div>
                <div className='ticket-Details mt-0 card mx-3'>
                    <div className='ticketNAme'>
                        <span>
                            {ReduceText(eventDetail?.event_name || '')}
                        </span>
                        <span className='ticketprice'>
                            {/* ₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </span>
                    </div>
                    <hr />
                    <div className='ticketdatetime'>
                        <span> {formatDate(eventDetail.event_start_date)}  {formatTime(eventDetail.event_start_Time)}</span>
                        <span>{eventDetail.event_city} </span>
                    </div>
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <span className='ticketNAme'>
                            {/* {
                ticket && ticket.length > 0 ? (
                  ticket.map((tic, index) => (
                    <span key={tic._id}>
                      {index > 0 ? "," : ''} {tic.ticket_Name}
                    </span>
                  ))
                ) : (
                  ""
                )
              } */}

                            <span>
                                {ticket.ticket_Name}
                            </span>
                        </span>
                        <span className='quantity'>{billing.ticketQuantity} Tickets</span>
                    </div>
                </div>
                <div style={{ height: "200px" }}>

                </div>
                <div className='smallfooterPart py-2'>
                    <div className='text-center'>
                        <button className="proceedpay-btn" onClick={handleGetTicket} disabled={loading}>
                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Book"}</button>
                    </div>
                </div>
            </div >

            {/* backto Product page confirmation modal */}
            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span>Are you sure you want to exit?</span>
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn primary_background text-white" data-bs-dismiss="modal"><span className='text-white'></span>Cancel</button>
                            <button type="button" className="btn secondary_background text-white" onClick={() => navigate(`/corporateevent/${eventDetail.event_name.replace(/\s+/g, '_')}/${eventDetail.corporate_event_id}`)} data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CorporateTicketBookingSummary