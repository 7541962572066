import React, { useEffect, useState } from "react";
import './SucessBookingTicketPage.css'
import CorporateNavBar from "../Components/corporateNavBar";
import LocationModal from "../Components/LocationModal";
import { BsArrowLeft } from "react-icons/bs";
import TickImg from '../../Assets/tick.png'
import { useNavigate } from "react-router-dom";

const CorporateSucessBookingTicketPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const user = JSON.parse(localStorage.getItem('CorporateEmp'));

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    const Navigate = useNavigate();
    const handleViewTicket = ()=>{
        Navigate('/corporateevent/yourtickets');
    }

     useEffect(() => {
                // Scroll to top when the component loads or data is done loading
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth", // Adds smooth scrolling
                    });
            }, []);

    return (
        <div>
            <CorporateNavBar userLocation={userLocation} onNext={() => setShowLocationModal(true)} isCrop={true} />
            {/* Content */}
            <div style={{ height: "100vh" }}>
                <div className="SuccessMssg">
                    <div className='row' id="largedeviceview">
                        <div className='col-md-1 col-sm-1'>
                            <span className='backarrowlink link-dark mt-2'><BsArrowLeft size={30} /></span>
                        </div>
                        <div className='col-md-11 col-sm-11'>
                            <div className='d-flex flex-column justify-content-center'>
                                <span className='event-name'>Ticket Details</span>
                            </div>
                        </div>
                    </div>
                    <div className="SuccessMssgDiv shadow text-center p-2">
                            <div className="textdiv mt-5 px-3">
                            We have sent tickets to <b>{user.Email}</b> and <b>{user.Phone_number}</b>.
                            You can also find your tickets in My account  &gt; My Front Seat
                            </div>
                            <div className="my-4">
                                <img src={TickImg} className="w-50 m-auto" alt="ticket_sucess_icon" />
                            </div>
                            <div>
                                <p className="fontSizeSeatBook">Front Seats Booked!</p>
                            </div>
                            <button className="viewticketbtn" onClick={handleViewTicket}>View Tickets</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateSucessBookingTicketPage;