import React, { useState } from 'react';
import './ProductDetail.css';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { SlArrowDown } from 'react-icons/sl';
import { TbMapPin } from "react-icons/tb";
import { Calendar, Clock } from 'react-feather';
import ReduceText from '../Interface';
import { GoShareAndroid } from "react-icons/go";
import { FiLink } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BsArrowLeft } from "react-icons/bs";
import SyncLoader from 'react-spinners/SyncLoader';

function CorporateEventProfile({ eventDetail }) {
    const navigate = useNavigate();
    const event = eventDetail;
    const [loading, setLoading] = useState(false);

    const handleTicketSelection = () => {
        setLoading(true);
        if (event.isAllSession || event.event_start_date === event.event_end_date) {
            navigate(`/ticketpage/${(event.event_name).replace(/\s+/g, '_')}/${event.corporate_event_id}`, { state: { date_for_booked_event: event.event_starts_date } });
            setLoading(false);
        } else {
            navigate(`/corporateevent/dateselect/${(event.event_name).replace(/\s+/g, '_')}/${event.corporate_event_id}`, { state: { event: event } });
            setLoading(false);
        }
    };


    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day} ${month} '${year}`;
    };

    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };

    const lowestTicket = (ticketprices) => {
        let lowestPrice = ticketprices[0].ticket_price;
        for (let i = 1; i < ticketprices.length; i++) {
            if (ticketprices[i].ticket_price !== 0 && lowestPrice > ticketprices[i].ticket_price) {
                lowestPrice = ticketprices[i].ticket_price;
            }
        }
        return lowestPrice;
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const getText = () => {
        if (isExpanded) {
            return event.event_description;
        }
        return event.event_description.length > 50 ? event.event_description.substring(0, 30) + "..." : event.event_description;
    };

    // copy link functionality
    const shareLink = window.location.href;
    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            alert("Link copied to clipboard!");
        }).catch(err => {
            alert("Failed to copy the link!");
        });
    };

    const handleShareWhatsApp = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareLink)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleShareFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`;
        window.open(facebookUrl, '_blank');
    };

    const handleShareTwitter = () => {
        const tweetText = "Check out this link!";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, '_blank');
    };

    const openGoogleMaps = () => {
        const venue = `${event.event_venue}, ${event.event_city}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venue)}`;
        window.open(googleMapsUrl, '_blank');
    };
    return (
        <>
            <div className='productDetailDiv' id="desktopView">
                <div className='row detailDiv'>
                    <div className='col-lg-8 col-md-8'>
                        <div className='Event_imageDiv'>
                            <img src={`${event?.cover_image}`} className='w-100 h-100 rounded-4' />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4'>
                        <div className='event-info'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-between align-items-center mb-1'>
                                    <span className='event_name'>{ReduceText(event?.event_name || '')}</span>
                                    <div className="dropdown">
                                        <span data-bs-toggle="dropdown" aria-expanded="false"><GoShareAndroid size={25} style={{ color: "#2C9CF0" }} /></span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item d-flex justify-content-between" onClick={handleCopyLink} type="button">
                                                    <span>Copy link  </span> <FiLink className='primarycolor' />
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item d-flex justify-content-between" onClick={handleShareWhatsApp} type="button">
                                                    <span>WhatsApp  </span> <IoLogoWhatsapp className='text-success' />
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item d-flex justify-content-between" onClick={handleShareFacebook} type="button">
                                                    <span>Facebook  </span> <FaFacebook className='text-primary' />
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item d-flex justify-content-between" onClick={handleShareTwitter} type="button">
                                                    <span>Twitter </span> <FaXTwitter />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='my-2'>
                                    <span className='event-category me-4' style={{ width: "5rem" }}>Corporate Event</span>
                                    <span className='event-detail'>
                                        {event?.Event_lang ? event.Event_lang : "Not Mention"} | {event?.Age_rest ? event.Age_rest + "yrs +" : "0"}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className='mb-4'>
                                    <span className='event-detail'>
                                        <TbMapPin /> {event?.event_venue ? event.event_venue + ',' : ''} {event?.event_city}
                                    </span>
                                    <Link className='directionspan' to="#" onClick={openGoogleMaps}>
                                        <span>Get Direction</span>
                                    </Link>
                                </div>
                                <div className='event-schedule flex-column mb-3'>
                                    <div className='event-timing fw-semibold'>
                                        <Calendar size={16} /> {formatDate(event?.event_start_date)} {event?.event_end_date && event?.event_start_date !== event.event_end_date ? ` - ${formatDate(event.event_end_date)}` : ''}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-end me-lg-5 me-md-2'>
                                    <p>
                                        <div className='event-timing fw-semibold'>
                                            <Clock size={16} /> {formatTime(event?.event_start_Time)} - {formatTime(event?.event_end_Time)}
                                        </div>
                                        {/* <span className='ticket-amt'>₹ {event?.tickets.length > 0 ? lowestTicket(event.tickets) : "0"} </span>
                                        <span className='fw-normal text-secondary' style={{ color: "#6A6A6A", fontSize: "24px" }}>onwards</span> */}
                                    </p>
                                </div>
                                {
                                    (new Date(new Date(event?.event_end_date).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))) ?
                                        <button className='booking-btn fs-5 rounded-3 mt-2 px-2' disabled style={{ background: "#6A6A6A" }}>
                                            Sold Out
                                        </button>
                                        :
                                        <button className='booking-btn fs-5 rounded-3 mt-2' onClick={handleTicketSelection} disabled={loading} style={{backgroundColor:"#F41E6D"}}>
                                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Book Now"}
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row aboutArtistDiv'>
                    <div className='col-lg-6'>
                        <div className='aboutArtist border border-light-subtle d-flex flex-column'>
                            <span className='fw-semibold profileHead mb-1'>About: </span>
                            <span className='profilesub' style={{ whiteSpace: 'pre-wrap' }}>{getText()}</span>
                            {event.event_description.length > 50 && (
                                <Link className='fw-normal readmore' to='#' onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </Link >
                            )}

                            <div className='d-flex flex-column mt-3'>
                                <span className='fw-semibold profile-Head mb-1'>Presented by: </span>
                                <span className='profilesub'>{event?.company_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='aboutArtist border border-light-subtle'>
                            <span className='fw-semibold profileHead'>Artist: </span>
                            {/* <div className='row artistSocialdetail'>
                                {
                                    event.Addon?.[0]?.Artists?.length > 0 && (
                                        event.Addon[0].Artists.map((artist) => (
                                            <div className='col-lg-4 col-md-4 col-sm-4 text-center' key={artist._id}>
                                                <img className="user-image" src={`${API_URL}/uploads/${artist.artist_pic}`} alt="User" />
                                                <p className='profilesub'>{artist.artist_name}</p>
                                            </div>
                                        ))
                                    )
                                }
                            </div> */}

                            <div className="artistSocialdetail">
                                <div className="artistItem">
                                    <img className="user-image" src={`${event.artist_picture}`} alt="User" />
                                    {/* <p className='profilesub'>{artist.artist_name}</p> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='lastDiv'>
                    {
                        (!event.venueLayout) ?
                            "" :
                            <div className='extraDetail fw-semibold mt-1 px-1'
                                data-bs-toggle="collapse" data-bs-target="#venueLoyout" aria-expanded="false" aria-controls="venueLoyout">
                                <span>Venue Layout</span>
                                <span className='me-3'><SlArrowDown className='arrowdown' size={16} /></span>
                            </div>
                    }
                    <div className="collapse my-3" id="venueLoyout">
                        {
                            (!event.venueLayout) ?
                                <div className='p-5 w-75 m-auto border border-dark m-5 text-center' style={{ minHeight: "250px", backgroundColor: "peach" }}>
                                    <span className='fs-1 fw-bold'>Venue Layout Image</span>
                                </div> :
                                <img className='layoutImg w-100 h-100' src={`${event.venueLayout}`} />
                        }
                    </div>
                    <div className='extraDetail fw-semibold mt-1 px-1'
                        data-bs-toggle="collapse" data-bs-target="#termscondition" aria-expanded="false" aria-controls="termscondition">
                        <span className='fw-semibold'>Terms & Conditions</span>
                        <span className='me-3'><SlArrowDown className='arrowdown' size={16} /></span>
                    </div>
                    <div className="collapse my-3" id="termscondition">
                        <div>
                            <ol className='profilesub'>
                                {event.termsCondition
                                    .split(/[\n.]+/)               // Split by newlines and periods
                                    .filter(condition => condition.trim() !== '')   // Filter out any empty strings
                                    .map((condition, index) => (
                                        <li key={index}>{condition.trim()}</li>     // Render non-empty conditions
                                    ))}
                            </ol>

                        </div>
                    </div>
                    <div className='extraDetail fw-semibold mt-1  px-1'
                        data-bs-toggle="collapse" data-bs-target="#guideline" aria-expanded="false" aria-controls="guideline">
                        <span>Guidelines</span>
                        <span className='me-3'><SlArrowDown className='arrowdown' size={16} /></span>
                    </div>
                    <div className="collapse my-3" id="guideline">
                        <div>
                            <ol className='profilesub'>
                                {event.guidelines
                                    .split(/[\n.]+/)
                                    .filter(condition => condition.trim() !== '')
                                    .map((condition, index) => (
                                        <li key={index}>{condition.trim()}</li>
                                    ))}
                            </ol>

                        </div>
                    </div>
                </div>
            </div>

            {/* Product Detail Page for Mobile View */}
            <div className='mb-5' id='mobileView'>
                <div className='Event_imageDiv'>
                    <span className='backarrow link-dark bg-secondary-subtle text-center rounded-circle mt-2'
                        onClick={() => navigate(-1)}
                        style={{ width: "2rem", height: "2rem" }} >
                        <BsArrowLeft className='mt-1' size={20} />
                    </span>
                    <img src={`${event?.cover_image}`}
                        className='w-100 h-100' />
                </div>
                <div className='p-3 d-flex flex-column px-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='fw-bold' style={{ fontFamily: "Poppins", fontSize: "24px" }}>
                            {event.event_name}
                        </span>
                        <div className="dropdown">
                            <span data-bs-toggle="dropdown" aria-expanded="false"><GoShareAndroid size={25} style={{ color: "#2C9CF0" }} /></span>
                            <ul className="dropdown-menu">
                                <li>
                                    <button className="dropdown-item d-flex justify-content-between" onClick={handleCopyLink} type="button">
                                        <span>Copy link  </span> <FiLink className='primarycolor' />
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item d-flex justify-content-between" onClick={handleShareWhatsApp} type="button">
                                        <span>WhatsApp  </span> <IoLogoWhatsapp className='text-success' />
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item d-flex justify-content-between" onClick={handleShareFacebook} type="button">
                                        <span>Facebook  </span> <FaFacebook className='text-primary' />
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item d-flex justify-content-between" onClick={handleShareTwitter} type="button">
                                        <span>Twitter </span> <FaXTwitter />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='event-lang mt-2'>
                        <span className='event-category me-3'>Corporate Event</span>
                        <span className='event-lang'>
                            {event.Addon?.[0]?.Event_lang ? event.Addon[0].Event_lang : "Not Mention"} | {event.Addon?.[0]?.Age_restriction ? event.Addon[0].Age_restriction + "yrs +" : "0"}
                        </span>
                    </div>
                    <div>
                        <div className='event-lang mt-2'>
                            <span>
                                <TbMapPin /> {event.event_venue ? event.event_venue + ',' : ''} {event.event_city}
                            </span>
                        </div>
                        <Link className='directionspan' to="#" onClick={openGoogleMaps}>
                            <span style={{ fontSize: "12px", color: "#2C9CF0", fontFamily: "Poppins", marginTop: "5px" }}>Get Direction</span>
                        </Link>

                    </div>
                    <div className='event-datetime'>
                        <span className='me-2 fw-semibold'><Calendar size={16} className='me-1' /> {formatDate(event.event_starts_date)} {event.event_ends_date && (event.event_starts_date !== event.event_ends_date) ? ` - ${formatDate(event.event_ends_date)}` : ''}</span>
                        <span className='fw-semibold ms-5'><Clock size={16} className='me-1' /> {formatTime(event?.event_starts_Time)}</span>
                    </div>
                </div>
                <div className='artistDivabout p-3' style={{ marginTop: "15px" }}>
                    <span className='artistHeading fw-semibold'>About: </span>
                    <p className='contents' style={{ whiteSpace: 'pre-wrap' }}>{getText()}</p>
                    {event.event_description.length > 100 && (
                        <Link className='fw-normal readmore' to='#' onClick={toggleReadMore}>
                            {isExpanded ? "Read Less" : "Read More"}
                        </Link >
                    )}
                    <div className='d-flex flex-column mt-3'>
                        <span className='artistHeading fw-semibold mb-1'>Presented by: </span>
                        <span className='contents'>{event.company_name}</span>
                    </div>
                </div>
                <div className='event-price px-2'>
                    <p className='mt-4'>
                        {/* <span className='event-ticket'>₹ {event.tickets.length > 0 ? lowestTicket(event.tickets) : "0"} </span><span style={{ color: "#6A6A6A", fontFamily: "Poppins", fontSize: "16px" }}>onwards</span> */}
                        </p>
                    {
                        (new Date(new Date(event.event_end_date).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))) ?
                            <button className='btn-booking bg-secondary text-white' disabled>
                                Sold Out
                            </button>
                            :
                            <button className='btn-booking text-white' onClick={handleTicketSelection} disabled={loading} style={{backgroundColor:"#F41E6D"}}>
                                {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Book Now"}
                            </button>
                    }
                </div>
                <div className='artistDiv p-3'>
                    <span className='artistHeading fw-semibold'>Artist :</span>
                    <div className="artistSocialdetail">
                            <div className="artistItem">
                                <img className="user-image" src={`${event.artist_picture}`} alt="User" />
                                {/* <div className='artist-name'>{artist.artist_name}</div> */}
                            </div>
                    </div>
                </div>
                <div>
                    {
                        (event.venueLayout) ? <div className='extraDetail fw-semibold mt-5'
                            data-bs-toggle="collapse" data-bs-target="#venueLoyout" aria-expanded="false" aria-controls="venueLoyout">
                            <span>Venue Layout</span>
                            <span><SlArrowDown className='arrowdown' /></span>
                        </div>
                            : ''
                    }         
                    <div className="collapse my-3" id="venueLoyout">
                        <img className='layoutImg w-100 h-100' src={`${event.venueLayout}`} />
                    </div>

                    <div className='extraDetail fw-semibold'
                        data-bs-toggle="collapse" data-bs-target="#ternCondition" aria-expanded="false" aria-controls="ternCondition">
                        <span>Terms & Conditions</span>
                        <span><SlArrowDown className='arrowdown' /></span>
                    </div>
                    <div className="collapse my-3" id="ternCondition">
                        <div>
                            <ol className='profilesub'>
                                {event.termsCondition
                                    .split(/[\n.]+/)               // Split by newlines and periods
                                    .filter(condition => condition.trim() !== '')   // Filter out any empty strings
                                    .map((condition, index) => (
                                        <li className='contents ps-4' key={index}>{condition.trim()}</li>     // Render non-empty conditions
                                    ))}
                            </ol>

                        </div>
                    </div>
                    <div className='extraDetail fw-semibold'
                        data-bs-toggle="collapse" data-bs-target="#guidelines" aria-expanded="false" aria-controls="guidelines">
                        <span>Guidelines</span>
                        <span><SlArrowDown className='arrowdown' /></span>
                    </div>
                    <div className="collapse my-3 mb-5" id="guidelines">
                        <div>
                            <ol className='profilesub'>
                                {event.guidelines
                                    .split(/[\n.]+/)               // Split by newlines and periods
                                    .filter(condition => condition.trim() !== '')   // Filter out any empty strings
                                    .map((condition, index) => (
                                        <li className='contents ps-4' key={index}>{condition.trim()}</li>     // Render non-empty conditions
                                    ))}
                            </ol>

                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "250px" }}>..
                </div>
            </div>
        </>
    );
}

export default CorporateEventProfile;
