import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Import signOut method
import './App.css';
import HomePage from './User/Pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import CategoryPage from './User/Pages/CategoryPage';
import ProductPage from './User/Pages/ProductPage';
import TicketPage from './User/Pages/TicketPage';
import BookingPage from './User/Pages/BookingPage';
import LoginPage from './User/Pages/LoginPage';
import AboutUsPage from './User/Pages/AboutUsPage';
import SignUpPage from './User/Pages/SignUpPage';
import PrivacyPolicy from './User/Pages/PrivacyPolicy';
import TermsCondition from './User/Pages/TermsCondition';
import UserProfile from './User/Pages/UserProfile';
import YourticketsPage from './User/Pages/YourTicketPage';
import ListyourEventPage from './User/Pages/ListyourEventPage';
import HostedEventPage from './User/Pages/HostedEventPage';
import auth from './firebaseConfig';
import SucessBookingTicketPage from './User/Pages/SucessBookingTicketPage';
import CorporateSucessBookingTicketPage from './User/Pages/CorporateSucessBookingTicketPage';
// import PaymentGateway from './User/Pages/PaymentGateway';
import DateSelect from './User/Components/DateSelect';
import CostumerSupportPage from './User/Pages/CostumerSupportPage';
import RewardsPage from './User/Pages/RewardsPage';
import { requestFCMToken, onMessageListener } from './utils/firebaseconfig_notification';
import { API_URL } from './config';
import axios from 'axios'
import LocationModal from './User/Components/LocationModal';
import MobileSearchView from './User/Components/MobileSearchView';
import CorporateEventProfilePage from './User/Pages/CorporateEventProfilePage';
import CorpDateSelect from './User/Components/CorpSelectDate';
import CorporateSelectTicket from './User/Components/CorporateSelectTicket';
import CorporateTicketSelectPage from './User/Pages/CorporateTicketSelectPage';
import CorporateEventBookingPage from './User/Pages/CorporateEventBookingPage';
import CorporateYourTicketPage from './User/Pages/CorporateYourTicketPage';

function App() {
  const navigate = useNavigate();
  const inactivityTimer = useRef(null);
  const user = JSON.parse(localStorage.getItem('userData'));
  const Copuser = JSON.parse(localStorage.getItem('CorporateEmp'));
  const [fcmToken, setFcmToken] = useState(null);

  const isAuthenticated = () => {
    return !!localStorage.getItem('userData');
  };

  const PrivateRoute = ({ element: Component }) => {
    return isAuthenticated() ? Component : <Navigate to="/login?source=yourtickets" />;
  };

  // Function to log out the user
  const logoutUser = () => {
    signOut(auth).then(() => {
      // Redirect to login page after sign-out
      localStorage.setItem('hasVisited', 'false');
      navigate('/login');
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  // Function to reset the inactivity timer
  const resetTimer = () => {
    // Clear the existing timer
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    // Set a new timer (e.g., 1 hour = 3600000 milliseconds)
    inactivityTimer.current = setTimeout(logoutUser, 3600000);
  };

  // Set up event listeners for user activity
  useEffect(() => {
    // Events to listen for user activity
    const events = ['click', 'mousemove', 'keypress', 'scroll'];

    // Add event listeners for each event type
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Set the initial timer
    resetTimer();

    // Clean up event listeners and timer on component unmount
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount


  const saveTokenToBackend = async (token) => {
    try {
      const response = await axios.put(`${API_URL}/admin/save-fcm-token`, { token });

      if (response.status === 200) {
        console.log("Token saved successfully!");
      } else {
        throw new Error("Failed to save token to backend. Status: " + response.status);
      }
    } catch (error) {
      console.error("Error saving token:", error);
    }
  };


  useEffect(() => {
    const fetchFCMToken = async () => {
      console.log("Notification function called")
      try {
        console.log("Notification function called")
        const token = await requestFCMToken();
        console.error('getting FCM token:', token);
        setFcmToken(token);
        await saveTokenToBackend(token);
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    fetchFCMToken();


  }, []);

  onMessageListener().then((payload) => {
    console.log("Received Message", payload)
  }).catch(error => {
    console.error('message error:', error);
  })

  const [showLocationModal, setShowLocationModal] = useState();
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      console.log('This runs only once when the user visits the website for the first time.');
      setShowLocationModal(true);
      // Store a flag in localStorage so it doesn't run again
      localStorage.setItem('hasVisited', 'true');
    }

    // Example: Fetch user data or set initial states
  }, []); // Empty dependency array ensures it runs only once

  // Function to handle location change
  const handleLocationChange = (location) => {
    // setUserLocation(location);
    setShowLocationModal(false); // Close modal after location change
  };

  // Function to handle closing the location modal
  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };
  return (
    <div>
      <>
        {showLocationModal && (
          <LocationModal
            onClose={handleCloseLocationModal}
            onLocationChange={handleLocationChange}
          />
        )
        }
      </>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/contact" element={<CostumerSupportPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/productpage/:id" element={<ProductPage />} />  {/*event_city/event_name/:id */}
        <Route path="/:event_city/:event_name/:id" element={<ProductPage />} />  {/*event_city/event_name/:id */}
        <Route path="/ticketpage/:event_name/:id" element={<TicketPage />} />
        <Route path="/dateselect/:event_name/:id" element={<DateSelect />} />
        <Route path="/eventbooking/:event_name/:event_id" element={<BookingPage />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/yourtickets/:booking_id" element={<PrivateRoute element={<YourticketsPage />} />} />
        <Route path="/yourtickets" element={<YourticketsPage />} />
        <Route path="/reward-points" element={<RewardsPage />} />
        <Route path="/listyourevent" element={<ListyourEventPage />} />
        <Route path="/ticketbooked/:event_name" element={<SucessBookingTicketPage />} />
        <Route path="/hosted-events" element={<HostedEventPage />} />
        <Route path="/search" element={<MobileSearchView />} />
        <Route path="/corporateevent/ticketpage/:event_name/:id" element={<CorporateTicketSelectPage />} />
        <Route path="/corporateevent/dateselect/:event_name/:id" element={<CorpDateSelect />} />
        <Route path="/corporateevent/booking-summary/:event_name/:event_id" element={<CorporateEventBookingPage />} />
        <Route path="/corporateevent/ticketbooked/:event_name" element={<CorporateSucessBookingTicketPage />} />
        <Route path="/corporateevent/:event_name/:id" element={<CorporateEventProfilePage />} />  {/*event_city/event_name/:id */}
        <Route path="/corporateevent/yourtickets" element={<CorporateYourTicketPage />} />
        <Route path="/corporate/yourticket/:booking_id" component={<CorporateYourTicketPage />} />
        </Routes>
    </div>
  );
}

export default App;
