import React, { useEffect, useState } from "react";
import './HostedEventPage.css';
import NavBar from "../Components/NavBar";
import UserSideBar from "../Components/userSideBar";
import LocationModal from "../Components/LocationModal";
import { Calendar, Clock } from 'react-feather';
import { API_URL } from "../../config";
import axios from "axios";

const HostedEventPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [events, setEvents] = useState([]);

    const getEventList = async () => {
        await axios.get(`${API_URL}/api/events`)
            .then((result) => {
                setEvents(result.data);
            })
            .catch((error) => {
                alert("Something went wrong");
            });
    }

    const totalticket = (ticket) => {
        let totaltickets = 0;
        for (let i = 0; i < ticket.length; i++) {
            totaltickets = totaltickets + ticket[i].total_Quantity;
        }
        return totaltickets.toLocaleString();
    }
    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };

    useEffect(() => {
        getEventList();
    }, []);

     useEffect(() => {
                // Scroll to top when the component loads or data is done loading
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth", // Adds smooth scrolling
                    });
            }, []);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    return (
        <>
            <div className='profilepage'>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <NavBar 
                userLocation={userLocation}
                onNext={() => setShowLocationModal(true)}/>
                <div className='row'>
                    <UserSideBar />
                    <div className='col-lg-10 col-md-10 col-sm-12 col-12'>
                    <div className='lists'>
                        <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                            <span className='profileheading fw-semibold'>Your Events</span>
                            {/* <Link to='/details' className='createeventbtn'><BiPlus size={15}/> Create Event</Link> */}
                        </div>
                        <div className='ListofEvents'>
                            <p className='listheading fw-semibold'>
                                {/* Your events */}
                            </p>
                            <div>
                                {events.length > 0 ? (
                                    events.map((event, index) => {
                                        return (
                                            <div className="row border border-dark mx-lg-1 rounded-4 p-1 mb-2" key={index}>
                                                <div className='col-lg-3 col-md-3 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'>{event.event_name} </span>
                                                    <span className='poppin14'>#MFS0001</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'><Calendar size={12} /> Date </span>
                                                    <span className='poppin14'>{new Date(event.event_starts_date).toLocaleDateString('en-GB').replace(/\//g, '-')}</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex justify-content-between'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='poppin18 fw-semibold'><Clock size={12} /> Time </span>
                                                        <span className='poppin14'>
                                                        {formatTime(event?.event_starts_Time)} - {formatTime(event?.event_ends_Time)}
                                                        </span>
                                                    </div>
                                                    <div className='border-end border-secondary-subtle border-2 mt-1 me-2' id="hideinSmall" style={{ height: "45px" }}></div>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'> Tickets Hosted </span>
                                                    <span className='poppin14'>{totalticket(event.ticket)}</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'> Venue </span>
                                                    <span className='poppin14'>{event.event_venue}</span>
                                                </div>
                                                <div className='col-lg-1 col-md-1 col-sm-4 col-4'>
                                                    <button className='border-primary mt-3 rounded-pill px-lg-3 bg-white text-primary'
                                                        style={{ marginLeft: "-10px", fontSize: "12px" }}>View</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : <p>.........No Event Added........</p>}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HostedEventPage;
