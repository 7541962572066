import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LocationModal from "../Components/LocationModal";
import CorporateNavBar from "../Components/corporateNavBar";
import SelectTickets from "../Components/SelectTickets";
import PuffLoader from "react-spinners/PuffLoader";
import CorporateSelectTicket from "../Components/CorporateSelectTicket";

function CorporateTicketSelectPage() {
    const { id } = useParams();
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [loading, setLoading] = useState(true); // New state to handle loading
    const location = useLocation();
    const { date_for_booked_event } = location.state

    // Simulate data fetching or loading process
    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setLoading(false); // Stop loading after 2 seconds (simulate)
        }, 2000); // Adjust timing or replace with actual data fetching logic

        return () => clearTimeout(loadTimeout); // Cleanup timeout on unmount
    }, []);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    useEffect(() => {
        // Scroll to top when the component loads or data is done loading
        if (!loading) {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Adds smooth scrolling
            });
        }
    }, [loading]);

    return (
        <div>
            <div id="hideforSmallDevices">
                <CorporateNavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)}
                    isCrop={true}/>
            </div>
            {
                loading ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <PuffLoader size={28}
                            loading={loading} />
                    </div>
                    :
                    < CorporateSelectTicket id={id} date_for_booked_event={date_for_booked_event} />
            }

        </div>
    );
}

export default CorporateTicketSelectPage;
