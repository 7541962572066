import React, { useEffect, useState } from 'react'
import './LocationModal.css'
import axios from "axios";
import { IoSearch } from "react-icons/io5";
import { API_URL } from '../../config';
import { BsXCircle } from "react-icons/bs";
import { useDispatch } from 'react-redux';

function LocationModal({ onClose, onLocationChange }) {
    const [searchCity, setSearchCity] = useState("");
    const [cityOptions, setCityOptions] = useState([]);
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
  
    useEffect(() => {
      const fetchSuggestions = async () => {
        if (query.length > 0) {
          try {
            const response = await axios.get(`${API_URL}/api/searchcity`, {
              params: { q: query }
            });
            setSuggestions(response.data);
          } catch (error) {
            console.error('Error fetching suggestions:', error);
          }
        } else {
          setSuggestions([]);
        }
      };
  
      fetchSuggestions();
    }, [query]);
  
    // Code for search entered city event
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key == 'onclick') {
        onLocationChange(query);
      }
  };
  const handleClick = () => {
    onLocationChange(query);
  };

  const dispatch = useDispatch();
    const handleLocation = (city) => {
      dispatch({
        type: "SetCity",
        payload: { city: city },
    });
      // onLocationChange(city); 
    }
    // code Modified  to here
  
  
  
  
    // const handleSearchChange = (event) => {
    //   setSearchCity(event.target.value);
    //   fetchCityOptions(event.target.value);
    // };
  
    // const fetchCityOptions = async (query) => {
    //   try {
    //     const response = await axios.get(
    //       `https://api.openweathermap.org/data/2.5/find?q=${query}&type=like&sort=population&cnt=10&appid=c605407cadf9250c840494482582a268`
    //     );
    //     const cities = response.data.list.map((city) => city.name);
    //     setCityOptions(cities);
    //   } catch (error) {
    //     console.error("Error fetching city options:", error);
    //   }
    // };
  
    // const handleSelectCity = (city) => {
    //   setSearchCity(city);
    //   setCityOptions([]); // Clear city options when a city is selected
    // };
  
    // const handleDetectLocation = () => {
    //   if (navigator.geolocation) {
    //     console.log("Attempting to detect location...");
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         console.log("Location detected successfully:", position.coords);
    //         const { latitude, longitude } = position.coords;
    //         onLocationChange({ latitude, longitude });
    //       },
    //       (error) => {
    //         console.error("Error getting location:", error);
    //       }
    //     );
    //   } else {
    //     console.error("Geolocation is not supported by this browser.");
    //   }
    // };
  
    const handleSearchSubmit = async (event) => {
      event.preventDefault();
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?q=${searchCity}&appid=c605407cadf9250c840494482582a268`
        );
        const { coord } = response.data;
        if (coord) {
          onLocationChange({ latitude: coord.lat, longitude: coord.lon });
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error("Error searching for city:", error.message);
      }
    };
  
    return (
      <div className="location-modal">
        <div className="location-content">
          <div className="location-info">
            {/* <span onClick={onClose}>
              <BsXCircle  className="closebtn fs-3" />
            </span> */}
            <div>
            <span className='locationHead main-PageHeading fw-semibold'>Select Your City</span>
              <div>
                <form onSubmit={handleSearchSubmit}>
                  <div className='searchdiv d-flex'>
                    <div className='search_magnify'>
                    <IoSearch className='SearchInputIcon text-primary fs-4'/>
                    </div>
                    <div className='search_Input'>
                      <input className="Search_Input_field"
                      type="text"
                      placeholder="Search your City"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                  {/* <datalist id="cityOptions">
                  {cityOptions.map((city, index) => (
                    <option
                      key={index}
                      value={city}
                      onClick={() => handleSelectCity(city)}
                    />
                  ))}
                </datalist> */}
                </form>
              </div>
              {
                suggestions.length > 0 ? (<div className="dropdown rounded-4 shadow-sm ">
                    <ul className="dropdown-menu list-group"  style={{maxHeight:"200px", overflowY:"scroll"}}>
                      {
                        suggestions.map((city, index) => (
                          <li className="dropdown-item" style={{cursor:"pointer"}} key={index} onClick={() => {
                            setQuery(city);
                            handleLocation(city);
                            handleClick();
                          }}>{city}</li>
                      ))
                      }
                    </ul>
                </div>) : ''
              }
            </div>
            {/* <div className="text-center mt-5">
              <button className="btn btn-primary rounded-pill px-5 mt-lg-5 mt-sm-3 mt-3" type="submit" onClick={handleLocation}>
                Search City
              </button>
            </div> */}
            {/* <div className="detect-loc">
              <TbCurrentLocation className="detect-icon" />
               <button className="detect-button" onClick={handleDetectLocation}>
                Detect my location
              </button> 
            </div> */}
          </div>
        </div>
      </div>
    ); 
}

export default LocationModal