import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './DateSelect.css';
import LocationModal from './LocationModal';
import NavBar from './NavBar';
import { BsArrowLeft } from "react-icons/bs";

const DateSelect = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [dates, setDates] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { event } = location.state; // Correctly destructure the event object

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    // Function to generate all dates between start and end date
    const getDatesInRange = (startDate, endDate) => {
        const date = new Date(startDate);
        const end = new Date(endDate);
        const datesArray = [];

        while (date <= end) {
            datesArray.push(new Date(date));
            date.setDate(date.getDate() + 1); // Move to the next day
        }

        return datesArray;
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day}-${month}-${year}`;
    };

    const formatTime = (timeString) => {
        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    };

    // Fetch dates in range when the component mounts or when event dates change
    // useEffect(() => {
    //     if (event && event.event_starts_date && event.event_ends_date) {
    //         const startDate = new Date(event.event_starts_date);
    //         const endDate = new Date(event.event_ends_date);
    //         const generatedDates = getDatesInRange(startDate, endDate);

    //         console.log("Generated Dates:", generatedDates);
    //         console.log("Event:", event);

    //         if (generatedDates.length > 0) {
    //             setDates(generatedDates);
    //         }
    //     }
    // }, [event]);
    useEffect(() => {
        if (event && event.event_starts_date && event.event_ends_date) {
            const startDate = new Date(event.event_starts_date);
            const endDate = new Date(event.event_ends_date);
    
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                console.error("Invalid start or end date", event.event_starts_date, event.event_ends_date);
                return;
            }
    
            const generatedDates = getDatesInRange(startDate, endDate);
    
            console.log("Generated Dates:", generatedDates);
            console.log("Event:", event);
    
            if (generatedDates.length > 0) {
                setDates(generatedDates);
            }
        }
    }, [event]);
    


    // Handle date selection and navigate to the ticket page
    const handleDateSelection = (selectedDate) => {
        navigate(`/ticketpage/${(event.event_name).replace(/\s+/g, '_')}/${event.unique_id}`, { state: { date_for_booked_event: selectedDate.toISOString().split('T')[0] } });
    };

    return (
        <>
            <div>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <div id="hideforSmallDevices">
                    <NavBar
                        userLocation={userLocation}
                        onNext={() => setShowLocationModal(true)}
                    />
                </div>
                <div className='ticketlist'>
                    <div className='row' id="largedeviceview">
                        <div className='col-md-1 col-sm-1'>
                            <span className='backarrowlink link-dark mt-2' onClick={() => navigate(-1)}><BsArrowLeft size={30} /></span>
                        </div>
                        <div className='col-md-11 col-sm-11'>
                            <div className='d-flex flex-column justify-content-center'>
                                <span className='event-name'>{event.event_name}</span>
                                <span className='event-detail'>{event.event_venue ? event.event_venue + "," : ''} {event.event_city} |
                                    {formatDate(event.event_starts_date)} | {formatTime(event?.event_starts_Time)}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id="smalldeviceview">
                        <div className="row">
                            <div className="col-1 d-flex justify-content-center align-items-center">
                                <span className='backarrow link-dark' onClick={() => navigate(-1)}><BsArrowLeft size={24} /></span>
                            </div>
                            <div className='col-11 d-flex flex-column justify-content-center align-items-center'>
                                <span className='event-name fw-semibold'>{event.event_name}</span>
                                <span className='event-detail'>
                                    {formatDate(event.event_starts_date)}  &nbsp;
                                    {event.event_city}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='head mb-3'>Select Date</div>
                        <div className='row sm-row mt-3'>
                            {dates
                                .map(date => new Date(date)) // Ensure dates are Date objects
                                .filter(date => {
                                    if (!event.ticket || event.ticket.length === 0) return false;
                                    const dateString = date.toISOString();

                                    return event.ticket.some(ticket => {
                                        const ticketDate = new Date(ticket.ticket_for_Date);
                                        if (isNaN(ticketDate.getTime())) {
                                            console.error("Invalid ticket date:", ticket.ticket_for_Date);
                                            return false;
                                        }
                                        return ticketDate.toISOString() === dateString;
                                    });

                                    // return event.ticket.some(ticket => new Date(ticket.ticket_for_Date).toISOString() === dateString);
                                })
                                .map((date, index) => {
                                    const dateString = date.toISOString();

                                    // Determine if tickets are sold out or selling fast
                                    const isSoldOut = event.ticket.every(ticket => ticket.ticket_for_Date === dateString && ticket.ticket_avability === 0);
                                    const isSellingFast = !isSoldOut && event.ticket.some(ticket => ticket.ticket_for_Date === dateString && ticket.ticket_avability > 0);

                                    return (
                                        <div className='sm-custom col-lg-3 col-md-3 col-sm-2 col-4 mb-3' key={index}>
                                            <p
                                                className={`dateSpanStyle text-center ${isSoldOut ? 'soldOut' : ''}`}
                                                onClick={!isSoldOut ? () => handleDateSelection(date) : null}
                                                style={isSoldOut ? { backgroundColor: '#6A6A6A', color: "white" } : {}}
                                            >
                                                {date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' })}
                                            </p>
                                            {isSoldOut
                                                ? <div className='text-center text-danger fw-semibold'>Sold Out</div>
                                                : <div className='text-center text-success fw-semibold'>Selling Fast</div>}
                                        </div>
                                    );
                                })}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default DateSelect;
